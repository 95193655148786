<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      class="card mb-1"
      title="Edit Data Staff"
    >
      <!-- Title -->
      <div class="d-flex pb-2">
        <b-avatar
          :src="blobImage ? blobImage : avatar"
          :text="avatarText('G')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              ref="file"
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              style="display: none"
              @change="onFileChange"
            >
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
          </div>
          <span>Upload foto avatar format jpg/png maks. 800kb</span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h6 class="mb-0">
          Data Pribadi
        </h6>
      </div>
      <form @submit.prevent="submitForm()">
        <div class="mt-2">
          <b-row>
            <!-- Nama Lengkap -->
            <b-col
              md="6"
              class="mb-1"
            >
              <label class="d-block">Nama Lengkap</label>
              <b-form-input
                v-model="formData.name"
                placeholder="Masukkan Nama Lengkap"
                :state="formErrors.name ? false : undefined"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.name ? formErrors.name[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <!-- Alamat Email -->
            <b-col
              md="6"
              class="mb-1"
            >
              <label class="d-block">Email</label>
              <b-form-input
                v-model="formData.email"
                :state="formErrors.email ? false : undefined"
                placeholder="Masukkan Email"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.email ? formErrors.email[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <!-- Password -->
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                description="Kosongkan jika tidak diganti"
                label="Password"
                :invalid-feedback="
                  formErrors.password ? formErrors.password[0] : ''
                "
                :state="formErrors.password ? false : undefined"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="formData.password"
                    :type="passwordFieldType"
                    :state="formErrors.password ? false : undefined"
                    placeholder="Masukkan Password"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Konfirmasi Password -->
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                description="Kosongkan jika tidak diganti"
                label="Konfirmasi Password"
                :invalid-feedback="
                  formErrors.password ? formErrors.password[0] : ''
                "
                :state="formErrors.password ? false : undefined"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="formData.password_confirmation"
                    :type="passwordFieldType"
                    placeholder="Masukkan Ulang Password"
                    :state="formErrors.password ? false : undefined"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Gelar Depan"
                :invalid-feedback="formErrors.gelar_depan ? formErrors.gelar_depan[0] : ''"
                :state="formErrors.gelar_depan ? false : undefined"
              >
                <b-form-input
                  v-model="formData.gelar_depan"
                  placeholder="Gelar Depan"
                  :state="formErrors.gelar_depan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Gelar Belakang"
                :invalid-feedback="formErrors.gelar_belakang ? formErrors.gelar_belakang[0] : ''"
                :state="formErrors.gelar_belakang ? false : undefined"
              >
                <b-form-input
                  v-model="formData.gelar_belakang"
                  placeholder="Gelar Belakang"
                  :state="formErrors.gelar_belakang ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Tempat Lahir"
                :invalid-feedback="formErrors.tempat_lahir ? formErrors.tempat_lahir[0] : ''"
                :state="formErrors.gelar_belakang ? false : undefined"
              >
                <b-form-input
                  v-model="formData.tempat_lahir"
                  placeholder="Tempat Lahir"
                  :state="formErrors.tempat_lahir ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Tanggal Lahir"
                :invalid-feedback="formErrors.tanggal_lahir ? formErrors.tanggal_lahir[0] : ''"
                :state="formErrors.tanggal_lahir ? false : undefined"
              >
                <flat-pickr
                  v-model="formData.tanggal_lahir"
                  class="form-control"
                  :class="{
                    'is-invalid': formErrors.tanggal_lahir,
                  }"
                  :config="{
                    altInput: true,
                    altFormat: 'j F Y',
                    dateFormat: 'Y-m-d',
                    maxDate: 'today'
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Golongan"
                :invalid-feedback="formErrors.golongan ? formErrors.golongan[0] : ''"
                :state="formErrors.golongan ? false : undefined"
              >
                <b-form-input
                  v-model="formData.golongan"
                  placeholder="Golongan"
                  :state="formErrors.golongan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Jabatan"
                :invalid-feedback="formErrors.jabatan ? formErrors.jabatan[0] : ''"
                :state="formErrors.jabatan ? false : undefined"
              >
                <b-form-input
                  v-model="formData.jabatan"
                  placeholder="Jabatan"
                  :state="formErrors.jabatan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Instansi"
                :invalid-feedback="formErrors.instansi ? formErrors.instansi[0] : ''"
                :state="formErrors.instansi ? false : undefined"
              >
                <b-form-input
                  v-model="formData.instansi"
                  placeholder="Instansi"
                  :state="formErrors.instansi ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="NIP"
                :invalid-feedback="formErrors.nip ? formErrors.nip[0] : ''"
                :state="formErrors.nip ? false : undefined"
              >
                <b-form-input
                  v-model="formData.nip"
                  placeholder="NIP"
                  :state="formErrors.nip ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="NIK"
                :invalid-feedback="formErrors.nik ? formErrors.nik[0] : ''"
                :state="formErrors.nik ? false : undefined"
              >
                <b-form-input
                  v-model="formData.nik"
                  placeholder="NIK"
                  :state="formErrors.nik ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="No Telepon"
                :invalid-feedback="formErrors.phone ? formErrors.phone[0] : ''"
                :state="formErrors.phone ? false : undefined"
              >
                <b-form-input
                  v-model="formData.phone"
                  placeholder="Nomor Telepon"
                  :state="formErrors.phone ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Role"
                :invalid-feedback="
                  formErrors.role ? formErrors.role[0] : ''
                "
                :state="formErrors.role ? false : undefined"
              >
                <b-form-select
                  v-model="formData.role"
                  :options="['TU', 'Staff']"
                  :state="formErrors.role ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Status"
                :invalid-feedback="
                  formErrors.status ? formErrors.status[0] : ''
                "
                :state="formErrors.status ? false : undefined"
              >
                <b-form-select
                  v-model="formData.status"
                  :options="['Aktif', 'Tidak Aktif', 'Diblokir']"
                  :state="formErrors.status ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <!-- Alamat -->
            <b-col
              lg="12"
              class="mb-1"
            >
              <b-form-group
                label="Alamat"
                :invalid-feedback="
                  formErrors.address ? formErrors.address[0] : ''
                "
                :state="formErrors.address ? false : undefined"
              >
                <b-form-textarea
                  v-model="formData.address"
                  :state="formErrors.address ? false : undefined"
                  placeholder="Alamat"
                  rows="3"
                  no-resize
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-button
              variant="primary"
              class="float-right"
              type="submit"
            >
              <div class="d-flex align-items-center">
                <b-spinner
                  v-show="isLoading"
                  class="mr-1"
                  variant="light"
                  small
                />
                Simpan
              </div>
            </b-button>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import { reqGetStaff, reqUpdateStaff } from '@/api/admin/staff'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormText,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: '',
      avatar: '',

      formErrors: {},

      religion: ['Islam', 'Kristen', 'Katolik', 'Hindu', 'Budha', 'Konghucu'],

      formData: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        address: '',
        avatar: '',
        role: '',
        status: '',
        gelar_depan: '',
        gelar_belakang: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        golongan: '',
        jabatan: '',
        instansi: '',
        nip: '',
        nik: ''
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.avatar = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true

      return isValid
    },
    async loadUser() {
      await reqGetStaff(this.$route.params.id)
        .then(response => {
          this.avatar = response.data.data.avatar
          this.formData.name = response.data.data.name
          this.formData.email = response.data.data.email
          this.formData.status = response.data.data.status
          this.formData.role = response.data.data.roles.length ? response.data.data.roles[0].name : ''

          this.formData.phone = response.data.data.profile.phone
          this.formData.address = response.data.data.profile.address
          this.formData.gelar_depan = response.data.data.profile.gelar_depan
          this.formData.gelar_belakang = response.data.data.profile.gelar_belakang
          this.formData.tempat_lahir = response.data.data.profile.tempat_lahir
          this.formData.tanggal_lahir = response.data.data.profile.tanggal_lahir
          this.formData.golongan = response.data.data.profile.golongan
          this.formData.jabatan = response.data.data.profile.jabatan
          this.formData.instansi = response.data.data.profile.instansi
          this.formData.nip = response.data.data.profile.nip
          this.formData.nik = response.data.data.profile.nik
        })
        .catch(error => {
          this.showToast(
            'danger',
            'AlertTriangleIcon',
            'Gagal',
            error.response.data.message || 'Data tidak ditemukan',
          )
        })
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        for (let i in this.formData) {
          if (this.formData[i]) {
            data.append(i, this.formData[i])
          }
        }

        await reqUpdateStaff(this.$route.params.id, data)
          .then(response => {
            this.isLoading = false
            this.formErrors = {}

            this.showToast(
              'success',
              'Check',
              'Berhasil',
              response.data.message || 'User Berhasil Disimpan',
            )

            this.loadUser()
          })
          .catch(error => {
            this.isLoading = false
            this.formErrors = error.response.data.errors

            this.showToast(
              'danger',
              'AlertTriangleIcon',
              'Gagal',
              error.response.data.message || 'User Gagal Disimpan',
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
